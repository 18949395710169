/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull monday_browser_sdk'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 33
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/appfire/7pace%20Timetracker/implementation/monday_browser_sdk)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'jirastaging' | 'mondayproduction' | 'mondaydevelopment' | 'mondayci' | 'mondaystaging' | 'jiradevelopment' | 'jiraci' | 'jirapreprod';

export const ApiKey: Record<Environment, string> = {
  jirastaging: '',
  mondayproduction: '',
  mondaydevelopment: '',
  mondayci: '',
  mondaystaging: '',
  jiradevelopment: '',
  jiraci: '',
  jirapreprod: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '33',
    branch: 'main',
    source: 'monday_browser_sdk',
    versionId: '2a97b477-e0e0-46da-a48c-519dbea296b3'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  user_email?: string;
  user_name?: string;
}

export interface BoardWidgetViewedProperties {
  /**
   * The associated Log Rocket session URL that would have captured the event
   */
  log_rocket_session?: string;
  /**
   * Type of monday.com widget created or viewed by a user.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Total Time, Planned vs Actual, Time Insight Report |
   */
  monday_widget_type: "Total Time" | "Planned vs Actual" | "Time Insight Report";
}

export interface CopyToBoardFinishedProperties {
  /**
   * The associated Log Rocket session URL that would have captured the event
   */
  log_rocket_session?: string;
  /**
   * Defines what board was used in 7pace for monday's Copy to board feature - an existing one or a new one (or some other alternative).
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Existing board, New board, Limit exceeded |
   */
  monday_board_status?: "Existing board" | "New board" | "Limit exceeded";
  /**
   * Number of worklogs in resulted event (e.g. copy to board, bulk edit..)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  number_of_worklogs?: number;
  /**
   * Number of days of export period
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  period_length_days?: number;
}

export interface CopyToBoardStartedProperties {
  /**
   * The associated Log Rocket session URL that would have captured the event
   */
  log_rocket_session?: string;
}

export interface LearnMoreClickedAuthorizationProperties {
  /**
   * The associated Log Rocket session URL that would have captured the event
   */
  log_rocket_session?: string;
}

export interface SubscriptionBannerViewedProperties {
  /**
   * The associated Log Rocket session URL that would have captured the event
   */
  log_rocket_session?: string;
  /**
   * The message that a banner or a blocking page communicates to the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Seats Exceeded, Public Preview Over, License Expired |
   */
  message_type?: "Seats Exceeded" | "Public Preview Over" | "License Expired";
}

export interface SubscriptionExpiredViewedProperties {
  /**
   * The associated Log Rocket session URL that would have captured the event
   */
  log_rocket_session?: string;
  /**
   * The message that a banner or a blocking page communicates to the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Seats Exceeded, Public Preview Over, License Expired |
   */
  message_type?: "Seats Exceeded" | "Public Preview Over" | "License Expired";
}

export interface SubscriptionPurchaseClickedProperties {
  /**
   * The associated Log Rocket session URL that would have captured the event
   */
  log_rocket_session?: string;
  /**
   * Determines a place on a page the event was triggered from
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Seats Exceeded Banner, Public Period Over Banner, Seats Exceeded Page, License Expired Page, Header button, Context menu, Times Explorer Bulk Edit - Apply, Times Explorer Bulk Edit - Undo, Times Explorer Bulk Edit - Cancel, Times Explorer monday import |
   */
  source?:
    | "Seats Exceeded Banner"
    | "Public Period Over Banner"
    | "Seats Exceeded Page"
    | "License Expired Page"
    | "Header button"
    | "Context menu"
    | "Times Explorer Bulk Edit - Apply"
    | "Times Explorer Bulk Edit - Undo"
    | "Times Explorer Bulk Edit - Cancel"
    | "Times Explorer monday import";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthorizeInDesktopClicked implements BaseEvent {
  event_type = 'AuthorizeInDesktopClicked';
}

export class AuthorizeInDesktopViewed implements BaseEvent {
  event_type = 'AuthorizeInDesktopViewed';
}

export class AuthorizeTimetrackerAccepted implements BaseEvent {
  event_type = 'AuthorizeTimetrackerAccepted';
}

export class AuthorizeTimetrackerClicked implements BaseEvent {
  event_type = 'AuthorizeTimetrackerClicked';
}

export class AuthorizeTimetrackerRejected implements BaseEvent {
  event_type = 'AuthorizeTimetrackerRejected';
}

export class AuthorizeTimetrackerViewed implements BaseEvent {
  event_type = 'AuthorizeTimetrackerViewed';
}

export class BoardWidgetViewed implements BaseEvent {
  event_type = 'BoardWidgetViewed';

  constructor(
    public event_properties: BoardWidgetViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyToBoardFinished implements BaseEvent {
  event_type = 'CopyToBoardFinished';

  constructor(
    public event_properties?: CopyToBoardFinishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyToBoardStarted implements BaseEvent {
  event_type = 'CopyToBoardStarted';

  constructor(
    public event_properties?: CopyToBoardStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LearnMoreClickedAuthorization implements BaseEvent {
  event_type = 'LearnMoreClickedAuthorization';

  constructor(
    public event_properties?: LearnMoreClickedAuthorizationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionBannerViewed implements BaseEvent {
  event_type = 'SubscriptionBannerViewed';

  constructor(
    public event_properties?: SubscriptionBannerViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionExpiredViewed implements BaseEvent {
  event_type = 'SubscriptionExpiredViewed';

  constructor(
    public event_properties?: SubscriptionExpiredViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionPurchaseClicked implements BaseEvent {
  event_type = 'SubscriptionPurchaseClicked';

  constructor(
    public event_properties?: SubscriptionPurchaseClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * AuthorizeInDesktopClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/AuthorizeInDesktopClicked)
   *
   * This event tracks when a user clicks on 'Authorize in web browser' button in the 7pace Timetracker authorization screen that is shown to a user in monday.com desktop app (we use a different auth screen than in a web browser for the desktop app).
   *
   * @param options Amplitude event options.
   */
  authorizeInDesktopClicked(
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeInDesktopClicked(), options);
  }

  /**
   * AuthorizeInDesktopViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/AuthorizeInDesktopViewed)
   *
   * This event tracks when a 7pace Timetracker authorization screen is shown to a user in monday.com desktop app (we use a different auth screen than in a web browser for the desktop app).
   *
   * @param options Amplitude event options.
   */
  authorizeInDesktopViewed(
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeInDesktopViewed(), options);
  }

  /**
   * AuthorizeTimetrackerAccepted
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/AuthorizeTimetrackerAccepted)
   *
   * User granted OAuth scopes to 7pace in a monday popup. The user is now authorized to view 7pace screens.
   *
   * Owner: Roman Fianta
   *
   * @param options Amplitude event options.
   */
  authorizeTimetrackerAccepted(
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeTimetrackerAccepted(), options);
  }

  /**
   * AuthorizeTimetrackerClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/AuthorizeTimetrackerClicked)
   *
   * User clicked on Grant Access on initial 7pace screen where 7pace asks the user to provide OAuth access to monday data (they may still change their mind in the subsequent monday.com popup though)
   *
   * Owner: Roman Fianta
   *
   * @param options Amplitude event options.
   */
  authorizeTimetrackerClicked(
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeTimetrackerClicked(), options);
  }

  /**
   * AuthorizeTimetrackerRejected
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/AuthorizeTimetrackerRejected)
   *
   * User rejected OAuth scopes to 7pace in a monday popup. The users cannot use 7pace Timetracker.
   *
   * Owner: Roman Fianta
   *
   * @param options Amplitude event options.
   */
  authorizeTimetrackerRejected(
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeTimetrackerRejected(), options);
  }

  /**
   * AuthorizeTimetrackerViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/AuthorizeTimetrackerViewed)
   *
   * User viewed OAuth authorization page in 7pace Timetracker
   *
   * Owner: Roman Fianta
   *
   * @param options Amplitude event options.
   */
  authorizeTimetrackerViewed(
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeTimetrackerViewed(), options);
  }

  /**
   * BoardWidgetViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/BoardWidgetViewed)
   *
   * This event tracks when a 7pace board widget is viewed by a user in monday.com.
   *
   * @param properties The event's properties (e.g. log_rocket_session)
   * @param options Amplitude event options.
   */
  boardWidgetViewed(
    properties: BoardWidgetViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BoardWidgetViewed(properties), options);
  }

  /**
   * CopyToBoardFinished
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/CopyToBoardFinished)
   *
   * User finalized the wizard for copying 7pace data to a monday.com board
   *
   * Owner: Roman Fianta
   *
   * @param properties The event's properties (e.g. log_rocket_session)
   * @param options Amplitude event options.
   */
  copyToBoardFinished(
    properties?: CopyToBoardFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyToBoardFinished(properties), options);
  }

  /**
   * CopyToBoardStarted
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/CopyToBoardStarted)
   *
   * Owner: Roman Fianta
   *
   * @param properties The event's properties (e.g. log_rocket_session)
   * @param options Amplitude event options.
   */
  copyToBoardStarted(
    properties?: CopyToBoardStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyToBoardStarted(properties), options);
  }

  /**
   * LearnMoreClickedAuthorization
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/LearnMoreClickedAuthorization)
   *
   * Event to track when a user clicks to learn more about authorization.
   *
   * @param properties The event's properties (e.g. log_rocket_session)
   * @param options Amplitude event options.
   */
  learnMoreClickedAuthorization(
    properties?: LearnMoreClickedAuthorizationProperties,
    options?: EventOptions,
  ) {
    return this.track(new LearnMoreClickedAuthorization(properties), options);
  }

  /**
   * SubscriptionBannerViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/SubscriptionBannerViewed)
   *
   * Subscription banner (e.g. Not enough seats, Public Preview period is over) displayed to the user.
   *
   * Owner: Roman Fianta
   *
   * @param properties The event's properties (e.g. log_rocket_session)
   * @param options Amplitude event options.
   */
  subscriptionBannerViewed(
    properties?: SubscriptionBannerViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionBannerViewed(properties), options);
  }

  /**
   * SubscriptionExpiredViewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/SubscriptionExpiredViewed)
   *
   * User sees a blocking page because there is no valid subscription for the account.
   *
   * @param properties The event's properties (e.g. log_rocket_session)
   * @param options Amplitude event options.
   */
  subscriptionExpiredViewed(
    properties?: SubscriptionExpiredViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionExpiredViewed(properties), options);
  }

  /**
   * SubscriptionPurchaseClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/7pace%20Timetracker/events/main/latest/SubscriptionPurchaseClicked)
   *
   * User clicks on the primary CTA button - purchase/buy/upgrade -  within a subscription banner or a blocking page.
   *
   * @param properties The event's properties (e.g. log_rocket_session)
   * @param options Amplitude event options.
   */
  subscriptionPurchaseClicked(
    properties?: SubscriptionPurchaseClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionPurchaseClicked(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
