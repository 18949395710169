import styled from "styled-components";

import { VirtualizeOptionProps } from "../../../models/VirtualizeOptionStyle";
import { DropdownLoader } from "./DropdownLoader";


type LoaderOptionProps = {
    text: string;
};

export const LoaderOption: React.FC<LoaderOptionProps & VirtualizeOptionProps> = ({ virtualizedStyle, text }) => {
    return (
        <LoaderOptionWrapper style={virtualizedStyle}>
            <LoaderOptionText>{text}</LoaderOptionText>
            <DropdownLoader />
        </LoaderOptionWrapper>
    );
};

const LoaderOptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${p => p.theme.spacing.s} calc(${p => p.theme.spacing.m} + ${p => p.theme.spacing.s});
`;

const LoaderOptionText = styled.span`
    color: ${p => p.theme.color.foreground.text.secondary};
    font: ${p => p.theme.typography.body.bolder.s};
`;
