import { FC } from "react";
import { TextWithOverflow } from "@7pace/components";
import styled from "styled-components";

import { UserApi } from "../../../components/AddTimeComponent/plugins/UserPlugin/hooks/useUsersQuery";
import { MondayUserAvatar } from "../../../components/MondayPrimitives/MondayUserAvatar";


type UserOptionValueProps = UserApi & {
    className?: string;
};

export const UserOptionValue: FC<UserOptionValueProps> = ({ className, ...user }) => {
    return (
        <UserOptionValueWrapper className={className}>
            <MondayUserAvatar user={user} customSize={26} />
            <DropdownOptionValue>{user.name}</DropdownOptionValue>
        </UserOptionValueWrapper>
    );
};

const UserOptionValueWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${p => p.theme.spacing.s};
`;

const DropdownOptionValue = styled(TextWithOverflow)`
    width: 100%;
    font: ${p => p.theme.typography.body.bolder.s};
`;
