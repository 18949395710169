import { useCallback, useEffect, useState } from "react";

import { BasePolyOption } from "../../../components/MondaySearch/components/DropdownList/components/RenderMenu";
import { UserDropdownOption } from "../types";


export enum UserOptionType {
    User, IsLoading, NoUsers
}

export type PolymorphicUserOption = BasePolyOption & {
    type: UserOptionType;
    user?: UserDropdownPolyOption;
};

export type UserDropdownPolyOption = UserDropdownOption & {
    isSelected?: boolean;
    onClick?: (item: PolymorphicUserOption) => void;
};

export const usePolyUserOptions = (
    options: UserDropdownOption[],
    selectedValue: number | null,
    isLoading: boolean,
    noItems: boolean,
    userHeight: number,
    onChange: (user: UserDropdownOption) => void
) => {
    const [polyUsers, setPolyUsers] = useState<PolymorphicUserOption[]>([]);

    const onClick = useCallback((polyUser: PolymorphicUserOption) => {
        onChange(polyUser.user);
    }, [onChange]);

    const createPolyItems = useCallback(() => {
        if (noItems) {
            const polyNoUsers: PolymorphicUserOption = {
                type: UserOptionType.NoUsers,
                height: userHeight
            };

            setPolyUsers([polyNoUsers]);
            return;
        }

        const res: PolymorphicUserOption[] = [];

        for (const user of options) {
            res.push({
                type: UserOptionType.User,
                height: userHeight,
                user: {
                    ...user,
                    isSelected: `${selectedValue}` === `${user.value}`,
                    onClick
                }
            });
        }

        if (isLoading) {
            const loadingPolyUser: PolymorphicUserOption = {
                type: UserOptionType.IsLoading,
                height: userHeight
            };
            res.push(loadingPolyUser);
        }

        setPolyUsers(res);
    }, [isLoading, noItems, onClick, options, selectedValue, userHeight]);

    useEffect(() => {
        createPolyItems();
    }, [createPolyItems]);

    return polyUsers;
};
