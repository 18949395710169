import { PredefinedTimeFilterType, TimesExplorerLayout } from "@7pace/times-explorer";
import { isDeepEqual } from "@7pace/utilities";

import { DEFAULT_LAYOUT_ORDER } from "../hooks/useTimesExplorerLayout";
import { MondayTimesExplorerLayout, MondayTimesExplorerLayouts } from "../types/TimesExplorerLayout";



export const getSettingsFromLayout = (layout: TimesExplorerLayout | MondayTimesExplorerLayout) => {
    const { columns, filters, groups } = layout;

    return { columns, filters, groups };
};

export const checkIfSettingsChanged = (prevLayout: TimesExplorerLayout | MondayTimesExplorerLayout, newLayout: TimesExplorerLayout | MondayTimesExplorerLayout) => {
    const prevLayoutSettings = getSettingsFromLayout(prevLayout);
    const newLayoutSettings = getSettingsFromLayout(newLayout);
    const prevTimeFilter = prevLayoutSettings.filters?.predefinedTimeFilter || PredefinedTimeFilterType.ThisMonth;
    const newTimeFilter = newLayoutSettings.filters?.predefinedTimeFilter || PredefinedTimeFilterType.ThisMonth;
    const prevTimeFrame = prevLayoutSettings.filters?.predefinedTimeFrame || PredefinedTimeFilterType.ThisMonth;
    const newTimeFrame = newLayoutSettings.filters?.predefinedTimeFrame || PredefinedTimeFilterType.ThisMonth;

    const didTimeFilterOrTimeFrameChanged = prevTimeFilter !== newTimeFilter || prevTimeFrame !== newTimeFrame;

    const didSettingsChange =
        !isDeepEqual(prevLayoutSettings.columns, newLayoutSettings.columns) ||
        !isDeepEqual(prevLayoutSettings.groups, newLayoutSettings.groups) ||
        !isDeepEqual(prevLayoutSettings.filters?.advancedFilter?.items || [], newLayoutSettings.filters?.advancedFilter?.items || [], ["id", "lastItemId"]) ||
        didTimeFilterOrTimeFrameChanged;

    return didSettingsChange;
};

export const getMaxOrder = (layouts: MondayTimesExplorerLayouts) => Object.values(layouts).reduce((max, l) => Math.max(max, l.order), DEFAULT_LAYOUT_ORDER);
