import { FC } from "react";
import styled from "styled-components";

import { DATA_LIST_OPTION_HOVERED, DATA_LIST_OPTION_SELECTED } from "../../../components/MondaySearch/hooks/useListOptionHighlight";
import { VirtualizeOptionProps } from "../../../components/MondaySearch/models/VirtualizeOptionStyle";
import { PolymorphicUserOption } from "../hooks/usePolyUserOptions";
import { UserOptionValue } from "./UserOptionValue";


export const UserOption: FC<PolymorphicUserOption & VirtualizeOptionProps> = ({ virtualizedStyle, index, ...props }) => {
    const { isSelected, onClick, ...rest } = props.user;

    const onClickInternal = () => {
        onClick?.(props);
    };

    return (
        <MondaySelectOptionWrapper
            data-list-option={index}
            data-list-option-selected={isSelected || undefined}
            style={virtualizedStyle}
            onClick={onClickInternal}
        >
            <UserOptionValue id={`${rest.value}`} name={rest.label} externalId={rest.externalId} />
        </MondaySelectOptionWrapper>
    );
};

const MondaySelectOptionWrapper = styled.div`
    // AZ: we're doing it this way to stick list's scrollbar to the right side
    width: calc(100% - 2 * ${p => p.theme.spacing.s}) !important;
    height: 100%;
    padding: ${p => p.theme.spacing.s} ${p => p.theme.spacing.s};
    margin: 0 ${p => p.theme.spacing.s};
    cursor: pointer;

    &[${DATA_LIST_OPTION_SELECTED}] {
        background-color: ${p => p.theme.color.background.interaction.active};
        border-color: ${p => p.theme.color.border.layout.subtle}; 
        border-radius: ${p => p.theme.radii.s};
    }

    &:not(&[${DATA_LIST_OPTION_SELECTED}]) {
        &[${DATA_LIST_OPTION_HOVERED}] {
            background-color: ${p => p.theme.color.background.interaction.hover};
            border-color: ${p => p.theme.color.border.layout.subtle};
            border-radius: ${p => p.theme.radii.s};
        }
    }
`;
