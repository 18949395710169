import { FC, useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { useTheme } from "styled-components";

import { LoaderOption } from "../../../components/MondaySearch/components/DropdownList/components/LoaderOption";
import { NoResultsOption } from "../../../components/MondaySearch/components/DropdownList/components/NoResultsOption";
import { getVirtualizedProps } from "../../../components/MondaySearch/utils/getVirtualizedProps";
import { PolymorphicUserOption, UserOptionType } from "../hooks/usePolyUserOptions";
import { UserOption } from "./UserOption";


export const UserPolyOptionVirtualized: FC<ListChildComponentProps<PolymorphicUserOption[]>> = (props) => {
    const theme = useTheme();
    const virtualizedProps = getVirtualizedProps(props);

    // AZ: adjust items top to apply vertical paddings in list
    const styleWithPadding = useMemo(() => {
        return {
            ...props.style,
            top: `calc(${props.style.top}px + ${theme.spacing.s})`
        };
    }, [props.style, theme.spacing.s]);

    switch (virtualizedProps.type) {
        case UserOptionType.User: return <UserOption virtualizedStyle={styleWithPadding} index={props.index} {...virtualizedProps} />;
        case UserOptionType.IsLoading: return <LoaderOption virtualizedStyle={styleWithPadding} index={props.index} text="Loading users..." />;
        case UserOptionType.NoUsers: return <NoResultsOption virtualizedStyle={styleWithPadding} index={props.index} />;
    }
};
