import { useEffect, useId, useMemo } from "react";
import { stringUtils } from "@7pace/utilities";
import { Dropdown } from "monday-ui-react-core";
import styled from "styled-components";

import { LoaderInputDropdown } from "./components/LoaderInputDropdown";
import { DropdownOption, DropdownOptionBase } from "./models/dropdownOptions";
import { MenuRendererProps } from "./models/menuRenderProps";
import { FIXED_DROPDOWN_CONTAINER_ID } from "../../../../../styles/FixedDropdownContainer";
import { baseMondaySelectStyles } from "../../../../slots/MondaySelectSlot/styles";
import { MondayInputError } from "../../../MondayPrimitives/MondayInputError";


export type DropdownStyles = {
    marginSize?: string;
    error?: string;
    size?: "small" | "medium";
};

export type DropdownProps = {
    options: DropdownOption[];
    id?: string;
    menuIsOpen?: boolean;
    value?: DropdownOption | DropdownOption[];
    defaultValue?: DropdownOption;
    cacheOptions?: boolean;
    placeholder?: string;
    tabIndex?: number;
    disabled?: boolean;
    showSpinner?: boolean;
    className?: string;
    label?: string;
    clearable?: boolean;
    searchable?: boolean;
    openMenuOnClick?: boolean;
    closeMenuOnSelect?: boolean;
    autoFocus?: boolean;
    "data-testid"?: string;
    onOptionSelect?: (data: DropdownOptionBase) => void;
    optionRenderer?: (data: DropdownOptionBase) => string | JSX.Element;
    valueRenderer?: (data: DropdownOptionBase | DropdownOptionBase[]) => string | JSX.Element;
    onClear?: () => void;
    menuRenderer?: (data: MenuRendererProps<object>) => JSX.Element;
    onInputChange?: (data: string) => void;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    onKeyDownCapture?: (event: KeyboardEvent) => void;
    fixedDropdownContainer?: boolean;
} & DropdownStyles;

const DropDownWrapper = styled(Dropdown) <DropdownStyles>`
    ${baseMondaySelectStyles};

    width: 100%;
    margin: ${props => props.marginSize};
    ${props => props.error ? "border: 1px solid var(--negative-color) !important;" : ""};
`;

export const DropdownList: React.FC<DropdownProps> = ({
    error,
    className,
    showSpinner = false,
    label,
    id,
    "data-testid": dataTestId,
    fixedDropdownContainer,
    ...props
}) => {
    const randomId = useId();
    const dropdownId = useMemo<string>(() => id || `dropdown-${randomId}`, [id, randomId]);
    const divElement = useMemo(() => document.createElement("div"), []);

    useEffect(() => {
        const dropdown = document.getElementById(dropdownId);
        const dropdownInput = dropdown.firstChild;

        divElement.id = `spinner-container-${dropdownId}`;

        dropdownInput.firstChild.insertBefore(divElement, dropdownInput.firstChild.lastChild);
    }, [divElement, dropdownId]);

    const internalClassName = stringUtils.constructClasses(
        "monday-dropdown",
        error && "invalid-input",
        className
    );

    return (
        <DropdownContainer data-testid={dataTestId}>
            {label && <Label>{label}</Label>}
            <DropDownWrapper
                {...props}
                id={dropdownId}
                error={error}
                className={internalClassName}
                openMenuOnFocus={true}
                tabSelectsValue={false}
                tabIndex={props.tabIndex ?? 0}
                popupsContainerSelector={fixedDropdownContainer ? `#${FIXED_DROPDOWN_CONTAINER_ID}` : undefined}
                dropdownMenuWrapperClassName="mdn-select-menu"
                singleValueWrapperClassName="mdn-select-single-value"
            />
            {showSpinner && <LoaderInputDropdown parent={divElement} />}
            {error && <MondayInputError>{error}</MondayInputError>}
        </DropdownContainer>
    );
};

const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${p => p.theme.spacing.xs};
`;

const Label = styled.div`
    color: ${p => p.theme.color.foreground.text.primary};
    font: ${p => p.theme.typography.body.regular.m};
`;
