import styled, { css } from "styled-components";

import { DropdownStyles } from "../DropdownList/DropdownList";


type SizeProps = Pick<DropdownStyles, "size">;

const placeholder = css<SizeProps>`
    ${p => {
        if (p.size === "small") {
            return css`
                        margin-left: 8px !important;
                        font: ${p.theme.typography.body.regular.m};
                    `;
        } else {
            return css`
                        margin-left: 14px !important;
                        font: ${p.theme.typography.body.bolder.s};
                    `;
        }
    }}
`;

export const MondaySearchContainer = styled.div<SizeProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    height: 100%;
    gap: ${p => p.theme.spacing.m};

    [class^=dropdownMenuWrapper] {
        left: -1px;
        width: calc(100% + 2px);
        margin-top: 3px;
        border-radius: ${p => p.theme.radii.l};
        box-shadow: ${p => p.theme.shadow.m};
        background-color: ${p => p.theme.color.background.surface.secondary};
        // GM: Name of the original animation is autogenerated
        // So we need to set our own here
        animation-name: fadeIn;
        z-index: 3;
    }

    // GM: Redefine animation for monday dropdown
    // Originally, z-index is -10000 for 0%
    // which causes nasty flickering during animation
    @keyframes fadeIn {
        0% {
            opacity: 0;
            z-index: 999;
            transform: translateY(var(--dropdown-menu-fadinY, -75px));
        }
        100% {
            opacity: 1;
            z-index: 999;
            transform: translateY(0px);
        }
    }

    .monday-dropdown {
        /* This style is used to remove free space in dropdown when input is disabled */
        input:disabled {
            margin-left: 0 !important;
        }

        /* This style is used for adding space to text when searching in the items input  */
        input:enabled {
            margin-left: ${p => p.size === "small" ? "8px" : "14px"} !important;
        }

        /* These styles are used to remove padding and margins from a dropdown using a regular expression to search for className*/
        [class^=" css-"]:first-child {
            margin: 0 !important;
            padding: 0 !important;

            [class$="-placeholder"] {
                ${placeholder}
            }

            [class$="-singleValue"] {
                width: 100%;
                margin: 0;
                padding: 0;
            }

            [class^="components-Dropdown-components-singleValue-singleValue-module"] {
                width: 100% !important;
                padding-inline: 0 !important;
            }
        }
    }
`;
