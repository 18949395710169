import { SelectOptionItem, SlotSize } from "@7pace/design";
import { Dropdown } from "monday-ui-react-core";

import { MondaySelectOptionItem } from "./MondaySelectSlot";


export const mapSelectSlotSize = (size: SlotSize) => {
    if (size == null) {
        return Dropdown.size.SMALL;
    }

    switch (size) {
        case SlotSize.Small:
        case SlotSize.Medium: return Dropdown.size.SMALL;
        case SlotSize.Large: return Dropdown.size.MEDIUM;
    }
};

export const mapSelectSlotOption = (option: SelectOptionItem): MondaySelectOptionItem => option && ({
    label: option.text,
    value: option.value
});
