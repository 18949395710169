import { FC, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AccessDenied } from "../../../common/AccessControl/components/AccessDenied";
import { MondayAccessControl } from "../../../common/AccessControl/MondayAccessControl";
import { useCacheStorage } from "../../../common/hooks/useCacheStorage";
import { useFeatureFlags } from "../../../common/hooks/useFeatureFlags";
import { REQUIRED_PERMISSIONS } from "../../../common/utils/constants";
import RoutePath from "../../../routing/RoutePath";
import { PageTabProps } from "../common/PageTab";
import { ObjectView } from "../ObjectView";


const MY_TIME_PATH_KEY = "7p_my_time_path";

const MyTime: FC = () => {
    const [storedLocation, storeLocation] = useCacheStorage<string>(MY_TIME_PATH_KEY);

    const location = useLocation();
    const navigate = useNavigate();

    const { monthlyRevampEnabled } = useFeatureFlags();

    useEffect(() => {
        storeLocation(location.pathname);
    }, [location.pathname, storeLocation]);

    useEffect(() => {
        if (!storedLocation) {
            return;
        }

        navigate(storedLocation);
        // if adding navigate to dependencies, it will navigate, after the location changes
        // which would cause the storedLocation to be set, whenever the location changes
        // disallowing the user to switch tabs
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedLocation]);

    const pages = useMemo(() => {
        const res: PageTabProps[] = [
            {
                path: RoutePath.Monthly,
                name: "Monthly",
                beta: monthlyRevampEnabled,
                "data-testid": "monthly-tab"
            },
            {
                path: RoutePath.Weekly,
                name: "Weekly",
                "data-testid": "weekly-tab"
            },
            {
                path: RoutePath.Timesheet,
                name: "Timesheet",
                "data-testid": "timesheet-tab"
            }
        ];

        return res;
    }, [monthlyRevampEnabled]);


    return (
        <MondayAccessControl
            fallbackComponent={<AccessDenied />}
            permissionsRequirement={REQUIRED_PERMISSIONS.VIEW_PAGE.MY_TIME}
        >
            <ObjectView pages={pages} />
        </MondayAccessControl>
    );
};

export default MyTime;
