import { MondayColumnType } from "../../../../common/MondayContext/models/mondayColumnType";
import { ConnectedColumnStatus } from "../../types";
import { BoardOverviewColumnProps } from "../components/BoardOverview/BoardOverviewColumn";


export type DropdownOption = {
    label: string;
    value: string;
    type?: MondayColumnType;
};

export class ConnectedColumnBase {
    boardId: number;
    columnId: string;
    status: ConnectedColumnStatus;
}

type ConnectedColumnData = {
    name: string;
    type: MondayColumnType;
};

export type ConnectedColumnsAddInput = {
    boardId: number;
    columnId: string;
} & Partial<ConnectedColumnData>;

export type ConnectedColumnsDeleteInput = {
    boardId: number;
    columnId: string;
};

export type ConnectedColumn = ConnectedColumnBase & ConnectedColumnData;

export type AddedConnectedColumn = {
    addConnectedColumn: {
        connectedColumn: ConnectedColumnBase;
    };
};

export type DeletedConnectedColumn = {
    deleteConnectedColumn: {
        connectedColumn: ConnectedColumnBase;
    };
};

export enum ConnectItemTypes {
    Items = "items",
    Subitems = "subitems"
}

export type BoardOverviewColumnIdProps = BoardOverviewColumnProps & {
    id: string;
    boardId: number;
    columnId: string;
};

