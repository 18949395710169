import { FC, useCallback, useMemo } from "react";
import { UserPickerSlotProps } from "@7pace/design";
import styled, { css } from "styled-components";

import { UserOptionValue } from "./components/UserOptionValue";
import { UserPickerMenu } from "./components/UserPickerMenu";
import { mapUserPickerSlotSize } from "./utils/mapUserPickerSlotSize";
import { UserApi } from "../../components/AddTimeComponent/plugins/UserPlugin/hooks/useUsersQuery";
import { DropdownList } from "../../components/MondaySearch/components/DropdownList/DropdownList";
import { MenuRendererProps } from "../../components/MondaySearch/components/DropdownList/models/menuRenderProps";
import { MondaySearchContainer } from "../../components/MondaySearch/components/styles/MondaySearch.style";
import { useDisableDropdownKeyboard } from "../../components/MondaySearch/hooks/useDisableDropdownKeyboard";
import useSearchableOptions, { SearchFn } from "../../components/MondaySearch/hooks/useSearchableOptions";
import { UserDropdownOption } from "./types";


export const MondayUserPickerSlot: FC<UserPickerSlotProps<UserApi>> = ({
    users,
    selectedUsers,
    loading,
    size,
    errorProps,
    placeholder,
    clearable,
    className,
    onChange,
    ...rest
}) => {
    const mapUserToDropdownOption = useCallback((user: UserApi): UserDropdownOption => user && ({
        value: user.id as unknown as number, // AZ: temporary hack because current DropdownOption is working only with number values
        label: user.name,
        externalId: user.externalId
    }), []);

    const value: UserDropdownOption = useMemo(() => {
        return selectedUsers?.length ? mapUserToDropdownOption(selectedUsers[0]) : null;
    }, [mapUserToDropdownOption, selectedUsers]);

    const options: UserDropdownOption[] = useMemo(() => users.map(mapUserToDropdownOption), [users, mapUserToDropdownOption]);

    const mappedSize = useMemo(() => mapUserPickerSlotSize(size), [size]);

    const showSpinner = !value && loading;

    const disableKeyDown = useDisableDropdownKeyboard();

    const onSearch: SearchFn<UserDropdownOption> = useCallback(
        (term, searchableOptions) => searchableOptions.filter(x => x.label.toLocaleLowerCase().includes(term)), []
    );

    const { searchedOptions, onInputChange } = useSearchableOptions(
        options,
        onSearch
    );

    const valueRender = useCallback((props: UserDropdownOption) => {
        return (
            <SelectedUserOptionValue
                id={`${props.value}`}
                name={props.label}
                externalId={props.externalId}
            />
        );
    }, []);

    const menuRender = useCallback((props: MenuRendererProps<UserDropdownOption>) => {
        return <UserPickerMenu {...props} />;
    }, []);

    const onOptionSelectInternal = useCallback((data: UserDropdownOption) => {
        const selectedUser = users.find(cur => cur.id === `${data.value}`);
        onChange?.([selectedUser]);
    }, [onChange, users]);

    const onClear = useCallback(() => {
        onChange?.([]);
    }, [onChange]);

    const isBulkEditUserPicker = className.includes("bulk-edit-user-plugin");

    return (
        <MondaySearchContainer className={className} size={mappedSize}>
            <DropdownListWrapper
                {...rest}
                value={value}
                showSpinner={showSpinner}
                options={searchedOptions}
                menuRenderer={menuRender}
                onOptionSelect={onOptionSelectInternal}
                onClear={onClear}
                clearable={clearable ?? false}
                placeholder={placeholder || "Select user"}
                valueRenderer={valueRender}
                onInputChange={onInputChange}
                error={errorProps?.message}
                onKeyDown={disableKeyDown}
                size={mappedSize}
                isUserSelected={!!value}
                fixedDropdownContainer={isBulkEditUserPicker}
            />
        </MondaySearchContainer>
    );
};

const DropdownListWrapper = styled(DropdownList) <{ isUserSelected: boolean; }>`
    ${p => p.isUserSelected && css`
        input {
            padding: 0 29px !important; 
        }
    `}
`;

const SelectedUserOptionValue = styled(UserOptionValue)`
    padding-left: ${p => p.theme.spacing.s};
`;
