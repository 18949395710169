import { CustomField } from "@7pace/components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PagedQueryCacheData } from "../../common/hooks/usePagedQuery";
import AuthResult from "../../features/Root/Authorization/models/AuthResult";
import { FingerprintResult } from "../../features/Root/FingerprintProvider/FingerprintInit";
import MondayUser from "../models/MondayUser";
import { RootState } from "../store";


type AppState = {
    user: MondayUser;
    authToken: string;
    authResult: AuthResult;
    promptOAuthReset: boolean;
    isAmplitudeInited: boolean;
    isATDPreviewMode: boolean;
    logRocketSessionUrl: string;
    atdDraftCustomField: CustomField;
    atdTimeFrameMode: boolean;
    fingerprint: FingerprintResult;
    pagedQueryCache: Record<string, PagedQueryCacheData>;
};

const initialState = {
    user: null,
    authToken: null,
    authResult: AuthResult.Loading,
    promptOAuthReset: false,
    isAmplitudeInited: false,
    isATDPreviewMode: false,
    logRocketSessionUrl: null,
    atdDraftCustomField: null,
    atdTimeFrameMode: false,
    fingerprint: {
        isFingerprintInited: false,
        fingerprintVisitorId: null
    },
    pagedQueryCache: {}
} as AppState;


export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<MondayUser>) => {
            state.user = action.payload;
        },
        setAuthToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload;
        },
        setAuthResult: (state, action: PayloadAction<AuthResult>) => {
            state.authResult = action.payload;
        },
        setPromptOAuthReset: (state, action: PayloadAction<boolean>) => {
            state.promptOAuthReset = action.payload;
        },
        setIsAmplitudeInited: (state, action: PayloadAction<boolean>) => {
            state.isAmplitudeInited = action.payload;
        },
        setIsATDPreviewMode: (state, action: PayloadAction<boolean>) => {
            state.isATDPreviewMode = action.payload;
        },
        setLogRocketSessionUrl: (state, action: PayloadAction<string>) => {
            state.logRocketSessionUrl = action.payload;
        },
        setAtdDraftCustomField: (state, action: PayloadAction<CustomField>) => {
            state.atdDraftCustomField = action.payload;
        },
        setAtdTimeFrameMode: (state, action: PayloadAction<boolean>) => {
            state.atdTimeFrameMode = action.payload;
        },
        setFingerprint: (state, action: PayloadAction<FingerprintResult>) => {
            state.fingerprint = action.payload;
        },
        updatePagedQueryCache: <T>(state, action: PayloadAction<{ queryKey: string; queryCache: PagedQueryCacheData<T>; }>) => {
            state.pagedQueryCache[action.payload.queryKey] = action.payload.queryCache;
        }
    }
});

export const {
    setUser,
    setAuthToken,
    setAuthResult,
    setPromptOAuthReset,
    setIsAmplitudeInited,
    setIsATDPreviewMode,
    setLogRocketSessionUrl,
    setAtdDraftCustomField,
    setAtdTimeFrameMode,
    setFingerprint,
    updatePagedQueryCache
} = appSlice.actions;


// Selectors
export const getUser = (state: RootState) => state.app.user;
export const getAccount = (state: RootState) => state.app.user?.account;
export const getAuthToken = (state: RootState) => state.app.authToken;
export const getAuthResult = (state: RootState) => state.app.authResult;
export const getPromptOAuthReset = (state: RootState) => state.app.promptOAuthReset;
export const getIsAmplitudeInited = (state: RootState) => state.app.isAmplitudeInited;
export const getIsAtdPreviewMode = (state: RootState) => state.app.isATDPreviewMode;
export const getLogRocketSessionUrl = (state: RootState) => state.app.logRocketSessionUrl;
export const getAtdCustomFieldDraft = (state: RootState) => state.app.atdDraftCustomField;
export const getAtdTimeFrameMode = (state: RootState) => state.app.atdTimeFrameMode;
export const getFingerprint = (state: RootState) => state.app.fingerprint;

export const getPagedQueryCache = <T>(state: RootState, queryKey: string) => state.app.pagedQueryCache[queryKey] as PagedQueryCacheData<T>;
export const getPagedQueryCacheKeyExist = (state: RootState, queryKey: string) => Object.keys(state.app.pagedQueryCache).includes(queryKey);

export default appSlice.reducer;
