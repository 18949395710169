import { useEffect, useState } from "react";

import { DropdownOption } from "../components/DropdownList/models/dropdownOptions";


const useSearchableOptions = <T extends DropdownOption>(
    options: T[],
    searchFn: (term: string, options: T[]) => T[]
) => {
    const [term, setTerm] = useState<string>("");
    const [searchedOptions, setSearchedOptions] = useState<T[]>(options);

    useEffect(() => {
        const searchTerm = term?.trim()?.toLocaleLowerCase();
        if (!searchTerm) {
            setSearchedOptions(options);
            return;
        }

        setSearchedOptions(searchFn(searchTerm, options));
    }, [options, term, searchFn]);

    return {
        searchedOptions,
        onInputChange: setTerm
    };
};

export type SearchFn<T extends DropdownOption> = Parameters<typeof useSearchableOptions<T>>[1];

export default useSearchableOptions;
