import { FC } from "react";
import { TextWithOverflow } from "@7pace/components";
import styled from "styled-components";

import { MondaySelectOptionItem } from "./MondaySelectSlot";


type MondaySelectSlotOptionProps = {
    data: MondaySelectOptionItem;
};

export const MondaySelectSlotOption: FC<MondaySelectSlotOptionProps> = ({ data }) => {
    return (
        <MondaySelectOptionWrapper>
            <DropdownOptionValue>{data.label}</DropdownOptionValue>
        </MondaySelectOptionWrapper>
    );
};

const MondaySelectOptionWrapper = styled.div`
    width: 100%;
`;

const DropdownOptionValue = styled(TextWithOverflow)`
    width: 100%;
    padding: 8px 0;
`;
