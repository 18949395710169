import ReactDOM from "react-dom";

import { DropdownLoader } from "./DropdownLoader";


type SpinnerInputDropdownProps = {
    parent: HTMLDivElement;
};

export const LoaderInputDropdown = ({ parent }: SpinnerInputDropdownProps) => {
    return ReactDOM.createPortal(<DropdownLoader />, parent);
};
