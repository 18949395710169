import { FC } from "react";
import { SearchSlotProps } from "@7pace/design";
import { Search } from "monday-ui-react-core";

import { mapTextInputSlotSize } from "../MondayTextInputSlot/utils";


export const MondaySearchSlot: FC<SearchSlotProps> = ({
    errorProps,
    slotRef,
    size,
    ...commonProps
}) => {
    return (
        <Search
            ref={slotRef}
            size={mapTextInputSlotSize(size)}
            validation={errorProps?.error && {
                status: "error",
                text: errorProps.message
            }}
            {...commonProps}
        />
    );
};
