import { FC, useCallback, useEffect, useState } from "react";
import { SelectOptionItem, SelectSlotProps } from "@7pace/design";
import { stringUtils } from "@7pace/utilities";
import { Dropdown } from "monday-ui-react-core";
import styled, { createGlobalStyle } from "styled-components";

import { FIXED_DROPDOWN_CONTAINER_ID } from "../../../styles/FixedDropdownContainer";
import { NO_RESULTS_LABEL } from "../../utils/constants";
import { MondaySelectSlotOption } from "./MondaySelectSlotOption";
import { baseMondaySelectStyles } from "./styles";
import { mapSelectSlotOption, mapSelectSlotSize } from "./utils";


// AZ: we map text to label to support multiselect
export type MondaySelectOptionItem = {
    value: string;
    label: string;
};

export const MondaySelectSlot: FC<SelectSlotProps> = ({
    options,
    selected,
    onChange,
    size,
    className,
    searchable,
    multiselect,
    clearable,
    ...rest
}) => {
    const [selectedOptions, setSelectedOptions] = useState(selected || []);

    const finalClassName = stringUtils.constructClasses("monday-dropdown", className);

    useEffect(() => {
        setSelectedOptions(selected);
    }, [selected, selectedOptions]);

    const onOptionSelect = useCallback(({ value, label }: MondaySelectOptionItem) => {
        const selectOption: SelectOptionItem = {
            value,
            text: label
        };
        const newSelectedOptions = multiselect && selectedOptions ? [...selectedOptions, selectOption] : [selectOption];
        setSelectedOptions(newSelectedOptions);
        onChange?.(newSelectedOptions);
    }, [multiselect, onChange, selectedOptions]);

    const onOptionRemove = useCallback((option: MondaySelectOptionItem) => {
        const newSelectedOption = selectedOptions.filter(o => o.value !== option.value);
        setSelectedOptions(newSelectedOption);
        onChange?.(newSelectedOption);
    }, [onChange, selectedOptions]);

    const onClear = () => {
        setSelectedOptions([]);
        onChange?.([]);
    };

    return (
        <DropdownContainer>
            <DropdownMenuStyles />
            <StyledDropdown
                {...rest}
                options={options.map(mapSelectSlotOption)}
                size={mapSelectSlotSize(size)}
                searchable={searchable || false}
                onOptionSelect={onOptionSelect}
                onOptionRemove={multiselect && onOptionRemove}
                onClear={onClear}
                clearable={clearable ?? false}
                value={selectedOptions?.map(mapSelectSlotOption) || []}
                valueRenderer={MondaySelectSlotOption}
                optionRenderer={MondaySelectSlotOption}
                className={finalClassName}
                noOptionsMessage={() => NO_RESULTS_LABEL}
                dropdownMenuWrapperClassName="mdn-select-menu"
                singleValueWrapperClassName="mdn-select-single-value"
                optionWrapperClassName="mdn-select-option"
                isVirtualized={true}
                menuPlacement={Dropdown.menuPlacements.AUTO}
                menuPosition={Dropdown.menuPositions.FIXED}
                popupsContainerSelector={`#${FIXED_DROPDOWN_CONTAINER_ID}`}
                multi={multiselect}
            />
        </DropdownContainer>
    );
};

const DropdownContainer = styled.div`
    .mdn-select-menu {
        z-index: 100;
    }

    & .advanced-filter-value-dropdown [class*='multiselect-chip-single-line'] {
        margin: 0 2px;
        max-width: calc(100% - 15px);
    }

    & .advanced-filter-value-dropdown>div>div>div {
        padding-top: 3px;
        padding-bottom: 3px;
    }
`;

const StyledDropdown = styled(Dropdown)`
    ${baseMondaySelectStyles};
`;

// AZ: looks hacky, but it provides better ux
// we nest vertical padding inside TextWithOverflow
// so tooltip will appear not only on text hover, but on any part of the option hover
const DropdownMenuStyles = createGlobalStyle`
    #${FIXED_DROPDOWN_CONTAINER_ID} {
        .mdn-select-option {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
`;
