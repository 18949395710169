import { FC } from "react";
import { IconSlotType } from "@7pace/design";
import AddSmall from "monday-ui-react-core/dist/icons/AddSmall";
import Alert from "monday-ui-react-core/dist/icons/Alert";
import Calendar from "monday-ui-react-core/dist/icons/Calendar";
import Check from "monday-ui-react-core/dist/icons/Check";
import CloseSmall from "monday-ui-react-core/dist/icons/CloseSmall";
import Comment from "monday-ui-react-core/dist/icons/Comment";
import ConvertToItem from "monday-ui-react-core/dist/icons/ConvertToItem";
import Delete from "monday-ui-react-core/dist/icons/Delete";
import Divider from "monday-ui-react-core/dist/icons/Divider";
import Download from "monday-ui-react-core/dist/icons/Download";
import Drag from "monday-ui-react-core/dist/icons/Drag";
import DropdownChevronDown from "monday-ui-react-core/dist/icons/DropdownChevronDown";
import DropdownChevronUp from "monday-ui-react-core/dist/icons/DropdownChevronUp";
import Duplicate from "monday-ui-react-core/dist/icons/Duplicate";
import Edit from "monday-ui-react-core/dist/icons/Edit";
import Education from "monday-ui-react-core/dist/icons/Education";
import Email from "monday-ui-react-core/dist/icons/Email";
import ExternalPage from "monday-ui-react-core/dist/icons/ExternalPage";
import Filter from "monday-ui-react-core/dist/icons/Filter";
import Group from "monday-ui-react-core/dist/icons/Group";
import Idea from "monday-ui-react-core/dist/icons/Idea";
import Launch from "monday-ui-react-core/dist/icons/Launch";
import LearnMore from "monday-ui-react-core/dist/icons/LearnMore";
import Lines from "monday-ui-react-core/dist/icons/Lines";
import MoreActions from "monday-ui-react-core/dist/icons/MoreActions";
import MoveArrowDown from "monday-ui-react-core/dist/icons/MoveArrowDown";
import MoveArrowUp from "monday-ui-react-core/dist/icons/MoveArrowUp";
import MyWeek from "monday-ui-react-core/dist/icons/MyWeek";
import NavigationChevronLeft from "monday-ui-react-core/dist/icons/NavigationChevronLeft";
import NavigationChevronRight from "monday-ui-react-core/dist/icons/NavigationChevronRight";
import Open from "monday-ui-react-core/dist/icons/Open";
import Settings from "monday-ui-react-core/dist/icons/Settings";
import Sort from "monday-ui-react-core/dist/icons/Sort";
import Undo from "monday-ui-react-core/dist/icons/Undo";
import WhatsNew from "monday-ui-react-core/dist/icons/WhatsNew";

import { ConnectThemed } from "./ConnectThemed";
import { IconProps } from "./types";


export const mondayIcons: Partial<Record<IconSlotType, FC<IconProps>>> = {
    [IconSlotType.ArrowUp]: MoveArrowUp,
    [IconSlotType.ArrowDown]: MoveArrowDown,
    [IconSlotType.ArrowUpRight]: ConnectThemed,
    [IconSlotType.ChevronLeft]: NavigationChevronLeft,
    [IconSlotType.ChevronRight]: NavigationChevronRight,
    [IconSlotType.ChevronDown]: DropdownChevronDown,
    [IconSlotType.ChevronDoubleDown]: MoreActions,
    [IconSlotType.ChevronUp]: DropdownChevronUp,
    [IconSlotType.Comment]: Comment,
    [IconSlotType.Plus]: AddSmall,
    [IconSlotType.Pen]: Edit,
    [IconSlotType.Bin]: Delete,
    [IconSlotType.Copy]: Duplicate,
    [IconSlotType.TimeFrame]: MyWeek,
    [IconSlotType.Stack]: Lines,
    [IconSlotType.Calendar]: Calendar,
    [IconSlotType.Drag]: Drag,
    [IconSlotType.Export]: Download,
    [IconSlotType.Close]: CloseSmall,
    [IconSlotType.Gift]: WhatsNew,
    [IconSlotType.Group]: Group,
    [IconSlotType.QuestionCircle]: LearnMore,
    [IconSlotType.Settings]: Settings,
    [IconSlotType.Sort]: Sort,
    [IconSlotType.Expand]: Open,
    [IconSlotType.School]: Education,
    [IconSlotType.Envelope]: Email,
    [IconSlotType.Rocket]: Launch,
    [IconSlotType.Lightbulb]: Idea,
    [IconSlotType.Minus]: Divider,
    [IconSlotType.Filter]: Filter,
    [IconSlotType.ZoomIn]: AddSmall,
    [IconSlotType.ZoomOut]: Divider,
    [IconSlotType.Undo]: Undo,
    [IconSlotType.ExternalLink]: ExternalPage,
    [IconSlotType.Import]: ConvertToItem,
    [IconSlotType.Check]: Check,
    [IconSlotType.Warning]: Alert
};
