import { Loader } from "monday-ui-react-core";
import styled from "styled-components";


const LoaderWrapper = styled.div`
    width: auto;
    height: auto;
    margin-right: 2px;
`;

export const DropdownLoader: React.FC = () => {
    return <LoaderWrapper>
        <Loader size={16} color={Loader.colors.SECONDARY} />
    </LoaderWrapper>;
};
