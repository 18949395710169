import { useCallback } from "react";

import { AllowedKeyCode } from "./useListOptionHighlight";


export const useDisableDropdownKeyboard = () => {
    const disableKeyDown = useCallback((event: KeyboardEvent) => {
        if (AllowedKeyCode[event.code]) {
            event.preventDefault();
            return false;
        }
    }, []);

    return disableKeyDown;
};
