import { FC, useCallback, useMemo } from "react";
import { createUUID } from "@7pace/utilities";
import Home from "monday-ui-react-core/dist/icons/Home";

import { AccessDenied } from "../../../common/AccessControl/components/AccessDenied";
import { MondayAccessControl } from "../../../common/AccessControl/MondayAccessControl";
import { REQUIRED_PERMISSIONS } from "../../../common/utils/constants";
import RoutePath from "../../../routing/RoutePath";
import { useAppSelector } from "../../../store/hooks";
import { DEFAULT_LAYOUT_ID } from "../../TimesExplorer/hooks/useTimesExplorerLayout";
import { useTimesExplorerLayoutsCustomEventDispatcher } from "../../TimesExplorer/hooks/useTimesExplorerLayoutsCustomEvents";
import { getTimesExplorerLayoutHasUnsavedChanges, getTimesExplorerLayouts } from "../../TimesExplorer/state/timesExplorerLayoutsReducer";
import { TimesExplorerLayoutChangeModes } from "../../TimesExplorer/types/TimesExplorerLayout";
import { PageTabProps } from "../common/PageTab";
import { PreparedPageTabWithMenuProps } from "../common/PageTabWithMenu";
import { pagesArePreparedPageTabWithMenuProps } from "../common/utils/pages";
import { useCustomTabHandling } from "../hooks/useCustomTabHandling";
import { useTimesExplorerSelectedLayoutId } from "../hooks/useTimesExplorerSelectedLayoutId";
import { ObjectView } from "../ObjectView";


const defaultPage: PageTabProps = {
    path: RoutePath.TimesExplorer,
    name: "Times Explorer",
    id: DEFAULT_LAYOUT_ID,
    icon: Home
};

const AllTime: FC = () => {
    const selectedLayoutId = useTimesExplorerSelectedLayoutId();
    const dispatchCustomEvent = useTimesExplorerLayoutsCustomEventDispatcher();
    const layouts = useAppSelector(getTimesExplorerLayouts);
    const hasUnsavedChanged = useAppSelector(getTimesExplorerLayoutHasUnsavedChanges);
    const tabs = useMemo(() => {
        if (!layouts) {
            return [];
        }

        return Object.values(layouts)
            .sort((a, b) => a.order - b.order)
            .map(layout => ({ id: layout.layoutId, name: layout.name }));
    }, [layouts]);

    const { customTabPageProps, getNewTabName } = useCustomTabHandling(tabs, RoutePath.TimesExplorer, defaultPage);

    const handleTabCreation = (tabName: string) => {
        const newTabName = getNewTabName(tabName);
        const newLayout = {
            name: newTabName,
            id: createUUID(),
        };
        dispatchCustomEvent({
            changeMode: TimesExplorerLayoutChangeModes.CREATE,
            layout: newLayout
        });

        return newLayout;
    };

    const handleTabRename = (id: string, tabName: string) => {
        const newTabName = getNewTabName(tabName);
        dispatchCustomEvent({
            changeMode: TimesExplorerLayoutChangeModes.RENAME,
            layout: {
                id,
                name: newTabName
            }
        });
    };


    const pages = customTabPageProps?.length
        ? [...customTabPageProps]
        : [defaultPage];

    const activeTab: PageTabProps | PreparedPageTabWithMenuProps = pagesArePreparedPageTabWithMenuProps(pages)
        ? pages.find(page => page.path.endsWith(selectedLayoutId))
        : pages.find(page => page.path === RoutePath.TimesExplorer);

    const checkIsUnsaved = useCallback(() => hasUnsavedChanged, [hasUnsavedChanged]);

    const handleTabClick = (id: string) => {
        dispatchCustomEvent({
            changeMode: TimesExplorerLayoutChangeModes.SWITCH,
            layout: { id }
        });
    };

    const handleTabSave = (id: string) => {
        dispatchCustomEvent({
            changeMode: TimesExplorerLayoutChangeModes.SAVE,
            layout: { id }
        });
    };

    const handleTabDelete = (id: string) => {
        dispatchCustomEvent({
            changeMode: TimesExplorerLayoutChangeModes.DELETE,
            layout: { id }
        });
    };

    return <MondayAccessControl
        fallbackComponent={<AccessDenied />}
        permissionsRequirement={REQUIRED_PERMISSIONS.VIEW_PAGE.TIMES_EXPLORER}
    >
        <ObjectView
            pages={pages}
            showMenuForTabs={true}
            tabsWithMenuProps={{
                activeTab,
                checkIsUnsaved,
                onTabClick: handleTabClick,
                onTabSave: handleTabSave,
                defaultTabId: DEFAULT_LAYOUT_ID
            }}
            tabEvents={{
                onTabCreate: handleTabCreation,
                onTabRename: handleTabRename,
                onTabDelete: handleTabDelete
            }}
        />
    </MondayAccessControl>;
};

export default AllTime;
