import { css } from "styled-components";


const selectBackground = css<{ disabled: boolean; }>`
    background-color: ${p => p.disabled ?
        p.theme.color.background.surface.disabled :
        p.theme.color.background.surface.primary
    };
`;

// AZ in monday-ui-react-core v2.102.0 dropdown's background is changed for dark theme
// Alex said that we want to keep it how it was before
export const baseMondaySelectStyles = css<{ disabled: boolean; }>`
    width: 100%;

    div[class$="control"] {
        > div:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            ${selectBackground}

            > div:not([class$="placeholder"]) {
                ${selectBackground}
            }
        }

        > div:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            ${selectBackground}
        }
    }


    div[class$="placeholder"] + div {
         ${selectBackground}
    }

    .mdn-select-single-value {
        width: 100%;
        ${selectBackground}

        + div {
            ${selectBackground}
        }
    }

    [class*="placeholder"] {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 10px);
    }
`;
