import styled from "styled-components";


export type DropdownMenuStyles = {
    menuHeight?: number;
};

const DropdownMenu = styled.div<DropdownMenuStyles>`
    height: ${p => p.menuHeight}px;
    overflow: hidden;
`;

export default DropdownMenu;
