import styled from "styled-components";


export const FIXED_DROPDOWN_CONTAINER_ID = "fixed-dropdown-container";
export const FIXED_POPOVER_CONTAINER_ID = "fixed-popover-container";

// https://style.monday.com/?path=/docs/technical-patterns-dropdowns-inside-pop-overs--docs
export const FixedDropdownContainer = styled.div.attrs(() => ({
    id: FIXED_DROPDOWN_CONTAINER_ID
}))`
    top: 0; // Set the container and the beginning of the web page
    right: 0; // Set the container and the beginning of the web page
    position: fixed; // The container should not move when scrolling the web page
    width: 100%; // The container should cover the whole viewport
    height: 100%; // The container should cover the whole viewport
    pointer-events: none; // The container should not block any event
    z-index: 999999; // The container z index should be higher from the z-index of the modal/dialog which contains the dropdown itself

    .mdn-select-menu {
        margin-left: -1px;
        width: calc(100% + 2px);
    }

    * {
        pointer-events: auto; // all container children should handle events as usual
    }
`;

// https://style.monday.com/?path=/docs/technical-patterns-dropdowns-inside-pop-overs--docs
export const FixedPopoverContainer = styled.div.attrs(() => ({
    id: FIXED_POPOVER_CONTAINER_ID
}))`
    top: 0; // Set the container and the beginning of the web page
    right: 0; // Set the container and the beginning of the web page
    position: fixed; // The container should not move when scrolling the web page
    width: 100%; // The container should cover the whole viewport
    height: 100%; // The container should cover the whole viewport
    pointer-events: none; // The container should not block any event
    z-index: 99999; // The container z index should be higher from the z-index of the modal/dialog which contains the dropdown itself

    * {
        pointer-events: auto; // all container children should handle events as usual
    }
`;
