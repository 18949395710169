import { FC, memo, useCallback, useMemo } from "react";
import { getThemeTokenValue } from "@7pace/design";
import { useTheme } from "styled-components";

import { useUsersQuery } from "../../../components/AddTimeComponent/plugins/UserPlugin/hooks/useUsersQuery";
import { RenderMenu } from "../../../components/MondaySearch/components/DropdownList/components/RenderMenu";
import { MenuRendererProps } from "../../../components/MondaySearch/components/DropdownList/models/menuRenderProps";
import { GetNextIdFunc, HighlightDirection } from "../../../components/MondaySearch/hooks/useListOptionHighlight";
import { usePolyUserOptions, UserOptionType } from "../hooks/usePolyUserOptions";
import { UserDropdownOption } from "../types";
import { UserPolyOptionVirtualized } from "./UserPolyOptionVirtualized";


export const UserPickerMenu: FC<MenuRendererProps<UserDropdownOption>> = memo(({
    options, selectOption, getValue, hasValue, setValue, maxMenuHeight
}) => {
    const theme = useTheme();
    const listPadding = useMemo(() => parseFloat(getThemeTokenValue(theme.spacing.s)), [theme.spacing.s]);
    const userOptionHeight = useMemo(() => parseFloat(getThemeTokenValue(theme.size.l)), [theme.size.l]);

    const selectedValue: UserDropdownOption = useMemo(() => {
        return hasValue ? getValue()[0] : null;
    }, [hasValue, getValue]);

    const { isLoading } = useUsersQuery();

    const isNoResults = !isLoading && options.length === 0;

    const aggregateOnChange = useCallback((item: UserDropdownOption) => {
        setValue(item);
        selectOption(item);
    }, [setValue, selectOption]);

    const polyUsers = usePolyUserOptions(options, selectedValue?.value, isLoading, isNoResults, userOptionHeight, aggregateOnChange);

    const getNextHighlightedId = useCallback((curId: number, direction: HighlightDirection): ReturnType<GetNextIdFunc> => {
        if (direction === "up") {
            const nextBackwardId = polyUsers.findLastIndex((cur, i) => i < curId && cur.type === UserOptionType.User);
            if (nextBackwardId !== -1) {
                return { nextId: nextBackwardId, isOverflow: false };
            }

            const firstIdFromEnd = polyUsers.findLastIndex((cur) => cur.type === UserOptionType.User);
            return { nextId: firstIdFromEnd, isOverflow: true };
        }

        if (direction === "down") {
            const nextForwardId = polyUsers.findIndex((cur, i) => i > curId && cur.type === UserOptionType.User);
            if (nextForwardId !== -1) {
                return { nextId: nextForwardId, isOverflow: false };
            }

            const firstIdFromStart = polyUsers.findIndex((cur) => cur.type === UserOptionType.User);
            return { nextId: firstIdFromStart, isOverflow: true };
        }

        return { nextId: 0, isOverflow: false };
    }, [polyUsers]);

    const onEnterSubmit = useCallback((curElemId: number) => {
        const polyUser = polyUsers[curElemId];
        if (!polyUser?.user || polyUser?.type !== UserOptionType.User) {
            return;
        }

        aggregateOnChange(polyUser.user);
    }, [aggregateOnChange, polyUsers]);

    const selectedIndex = useMemo(() => {
        return polyUsers.findIndex((cur) => cur.user && cur.user.value === selectedValue?.value);
    }, [polyUsers, selectedValue?.value]);

    const menuHeight = useMemo(() => {
        const itemsHeight = polyUsers.length * userOptionHeight + listPadding * 2;
        return Math.min(itemsHeight, maxMenuHeight);
    }, [listPadding, maxMenuHeight, polyUsers.length, userOptionHeight]);

    return (
        <RenderMenu
            options={polyUsers}
            menuHeight={menuHeight}
            selectedIndex={selectedIndex}
            virtualizedItem={UserPolyOptionVirtualized}
            data-testId="atd-user-dropdown"
            getNextHighlightedId={getNextHighlightedId}
            onEnterSubmit={onEnterSubmit}
        />
    );
});
