import { FC, forwardRef, useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListChildComponentProps, VariableSizeList as List } from "react-window";
import { useTheme } from "styled-components";

import { GetNextIdFunc, OnEnterSubmitFunc, useListOptionHighlight } from "../../../hooks/useListOptionHighlight";
import { useScrollToSelectedOption } from "../../../hooks/useScrollToSelectedOption";
import DropdownMenu, { DropdownMenuStyles } from "../../styles/DropdownMenuStyle";


export type BasePolyOption = {
    height: number;
};

type RenderMenuProps = DropdownMenuStyles & {
    options: BasePolyOption[];
    selectedIndex?: number;
    virtualizedItem: FC<ListChildComponentProps<unknown>>;
    "data-testId"?: string;
    getNextHighlightedId: GetNextIdFunc;
    onEnterSubmit?: OnEnterSubmitFunc;
};

export const RenderMenu: React.FC<RenderMenuProps> = ({
    options,
    selectedIndex,
    virtualizedItem,
    menuHeight,
    getNextHighlightedId,
    onEnterSubmit,
    ...rest
}) => {
    const { listRef, onSetListRef } = useScrollToSelectedOption(selectedIndex);

    const [inner, setInner] = useState<HTMLDivElement>();
    const onSetInnerRef = (elem: HTMLDivElement) => {
        setInner(elem);
    };

    useListOptionHighlight(inner, listRef.current, getNextHighlightedId, onEnterSubmit);

    // AZ: push VariableSizeList to call itemSize callback to recalculate items height whenever options changed
    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [listRef, options]);

    return (
        <DropdownMenu menuHeight={menuHeight} data-testid={rest["data-testId"]}>
            {options?.length > 0 && (
                // AZ: we don't want to rely on AutoSizer's height because it updates with delay (~10ms) 
                // which leads to scrollbar blinking
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <List
                            height={menuHeight}
                            width={width}
                            itemCount={options.length}
                            itemData={options}
                            itemSize={(index) => options[index].height}
                            innerElementType={InnerElementType}
                            ref={onSetListRef}
                            innerRef={onSetInnerRef}
                        >
                            {virtualizedItem}
                        </List>
                    )}
                </AutoSizer>
            )}
        </DropdownMenu >
    );
};

// AZ: add vertical paddings to list's height
const InnerElementType = forwardRef<HTMLDivElement, ListChildComponentProps>(({ style, ...rest }, ref) => {
    const theme = useTheme();

    return (
        <div
            ref={ref}
            style={{
                ...style,
                height: `calc(${style.height}px + ${theme.spacing.s} * 2)`
            }}
            {...rest}
        />
    );
});
